import { Hub } from "../hub"
import { IHub } from "../interfaces/IHub"
import { HubPinnedMessages, isApiMessage } from "../../models/Dialogs/message"
import { DialogBadge } from "../../models/Dialogs/dialog"
import { Store } from "../../store/store"
import i18next from "i18next"
import { actions } from "../../store/dialogs/slice"
import { handleEvents, isCurrentUserSender, isStarterMessage, isSystemMessage, notifyOperator } from "./helpers"
import { logHubError } from "../../utility/common/logError"
import { NotificationsTypes } from "../../utility/common/TabNotifications"
import { dialogsApi } from "../controllers/dialogs"
import {
    refetchActiveDialogsCount,
    refetchDialogBadges,
    selectDialogBadges,
    updateCurrentDialogBadgeWithOutgoing,
    updateDialogBadge,
    updateOperatorClientsBadges,
    upsertDialogBadges
} from "../controllers/dialogs.helpers"
import { DialogsHubEvents, DialogsHubEventTypes as Events } from "../../models/Dialogs/hub"
import { selectDialogId, selectMessagesPaginationState } from "../../store/dialogs/selectors"
import { getAIAssistHintByDialogId, getAISuggestsByDialogId } from "../../store/dialogs/thunks"
import { getClientFullname } from "../../utility/dialogs/client"
import { MESSAGES_PER_PAGE } from "../../utility/dialogs/constant"
import { Dispatch } from "../../utility/common/storeHelper"
import { selectCurrentProjectId } from "../../store/projects/selectors"

const tDialogsNotificationsNamespace = "dialogs:notifications."

const HUB_NAME = "DialogsHub"

export class DialogsHub {
    private _hub: IHub
    private _store: Store
    private _events: DialogsHubEvents

    constructor(store: Store) {
        const reduxState = store.getState()
        const useAllTransportSignalR =
            reduxState.config.config.data?.WebConfig.appSettings.useAllTransportSignalR === true

        this._hub = new Hub(`/dialogs-hub-v2`, useAllTransportSignalR)
        this._store = store

        this._events = this.createEvents(this._store, this._store.dispatch)
        this._hub.reconnectedCallback = async () => {
            await this.subscribe()
            this.registerServerEvents()
        }
    }

    async subscribe() {
        await this._hub.subscribe("Subscribe")
    }

    async unsubscribe() {
        await this._hub.unsubscribe("Unsubscribe")
    }

    getInstance(): IHub {
        return this._hub
    }

    private checkOldWorkplaceLoaded() {
        const oldWPNode = document.querySelector<HTMLIFrameElement>('[name="crafttalk-workplace-old-frame"]')

        if (oldWPNode && getComputedStyle(oldWPNode).display === "block") {
            const oldDailogsNode = oldWPNode.contentDocument?.querySelector("#dialogs")

            if (oldDailogsNode && getComputedStyle(oldDailogsNode).display === "block") {
                throw new Error("Old workspace frame node already loaded. Passing")
            }
        }
    }

    // TODO: https://gitlab.crafttalk.ru/aibotdev/core/opbot/-/merge_requests/6872#note_47279
    private createEvents(store: Store, dispatch: Dispatch) {
        return {
            [Events.DETACH_DIALOG]: async (data: unknown) => {
                try {
                    const { Id: detachedDialogId } = data as { Id: string }
                    const selectedDialogId = selectDialogId(this._store.getState())
                    const dialogBadges = selectDialogBadges()
                    const isSelectedDialogDetached = selectedDialogId === detachedDialogId
                    let nextCurrentDialogId = ""

                    if (dialogBadges && dialogBadges.length > 0) {
                        let nextCurrentClientId = ""
                        const projectId = dialogBadges[0].Project.Id
                        const client = dialogBadges.find(dialogBadge => dialogBadge.Id === detachedDialogId)?.Client
                        const filteredDialogBadges = dialogBadges.filter(
                            dialogBadge => dialogBadge.Id !== detachedDialogId
                        )

                        if (filteredDialogBadges.length > 0) {
                            nextCurrentDialogId = filteredDialogBadges[0].Id
                            nextCurrentClientId = filteredDialogBadges[0].Client.OmniUserId
                        }

                        if (client) {
                            const operatorClientsBadgesSelector = dialogsApi.endpoints.getOperatorClientsBadges.select()
                            const operatorClientsBadges = operatorClientsBadgesSelector(store.getState())

                            if (Array.isArray(operatorClientsBadges.data)) {
                                dispatch(updateOperatorClientsBadges(client, projectId))
                            }
                        }

                        if (isSelectedDialogDetached) {
                            dispatch(
                                actions.setMessagesPaginationState({
                                    OmniUserId: nextCurrentClientId,
                                    ProjectId: projectId,
                                    FromTodayDialogs: true,
                                    StartTime: 0,
                                    Count: MESSAGES_PER_PAGE
                                })
                            )
                        }
                        dispatch(upsertDialogBadges(filteredDialogBadges))
                    }

                    dispatch(refetchActiveDialogsCount())

                    if (isSelectedDialogDetached) {
                        dispatch(actions.setCurrentDialogId(nextCurrentDialogId))
                    }
                } catch (e) {
                    logHubError(HUB_NAME, Events.DETACH_DIALOG, e)
                }
            },
            [Events.HANDLE_ROUTED_DIALOG]: async (data: unknown) => {
                try {
                    this.checkOldWorkplaceLoaded()

                    const { Badge: badge } = data as { Badge: DialogBadge }
                    const dialogId = badge.Id
                    const projectId = badge.Project.Id
                    const dialogBadges = selectDialogBadges()
                    const title = i18next.t(`${tDialogsNotificationsNamespace}new-dialog`)
                    const clientFullname =
                        getClientFullname(badge.Client) || i18next.t(`${tDialogsNotificationsNamespace}unknown-client`)
                    const body = i18next.t(`${tDialogsNotificationsNamespace}client`) + " " + clientFullname
                    const selectedDialogId = selectDialogId(store.getState())
                    const clientOmniUserId = badge.Client.OmniUserId

                    if (dialogBadges && !selectedDialogId) {
                        dispatch(actions.setCurrentDialogId(dialogId))
                        dispatch(
                            dialogsApi.endpoints.getDialogTopics.initiate({
                                ProjectId: projectId
                            })
                        )
                        dispatch(
                            actions.setMessagesPaginationState({
                                OmniUserId: clientOmniUserId,
                                ProjectId: projectId,
                                StartTime: 0,
                                Count: MESSAGES_PER_PAGE
                            })
                        )
                    }

                    dispatch(
                        upsertDialogBadges([
                            badge,
                            ...(dialogBadges?.filter(existingBadge => existingBadge.Id !== badge.Id) || [])
                        ])
                    )

                    dispatch(actions.unsetCurrentOperatorClientId())
                    dispatch(refetchActiveDialogsCount())

                    notifyOperator(
                        {
                            title,
                            body,
                            projectId,
                            dialogId,
                            clientId: badge.Client.OmniUserId,
                            dispatch: store.dispatch
                        },
                        { type: NotificationsTypes.DialogRouted }
                    )
                } catch (e) {
                    logHubError(HUB_NAME, Events.HANDLE_ROUTED_DIALOG, e)
                }
            },
            [Events.HANDLE_MESSAGE]: async (message: unknown) => {
                try {
                    this.checkOldWorkplaceLoaded()

                    if (!isApiMessage(message)) {
                        return
                    }

                    if (isSystemMessage(message)) {
                        return
                    }

                    if (isStarterMessage(message)) {
                        // https://youtrack.craft-talk.ru/issue/CLOUD-4670#focus=Comments-4-71343.0-0
                        message.Fields.Direction = 1
                        const currentOperator = store.getState().users.currentUser
                        message.Fields.Sender.Id =
                            currentOperator.data?.Login || `DEFAULT___${currentOperator.data?.Login}`
                    }

                    const currentMessagePagination = selectMessagesPaginationState(store.getState())
                    const selectedDialogId = selectDialogId(store.getState())

                    let dialogBadges = selectDialogBadges()
                    const isCached = dialogBadges?.some(x => x.Client.OmniUserId === message.Fields.Sender.Id)

                    if (isCurrentUserSender(message, store)) {
                        const isHoldEnabled =
                            message.Fields.Context?.find(ctx => ctx?.[0] === "hold:status")?.[1] === "enabled"
                        if (isHoldEnabled) {
                            const badgeIndex = dialogBadges?.findIndex(
                                dialogBadge => dialogBadge.Client.OmniUserId === currentMessagePagination?.OmniUserId
                            )

                            if (badgeIndex !== undefined && badgeIndex > -1) {
                                const badge = dialogBadges?.[badgeIndex]
                                if (badge) {
                                    dispatch(updateDialogBadge(badgeIndex, { ...badge, IsHoldEnabled: true }, message))
                                }
                            }
                        }
                        dispatch(updateCurrentDialogBadgeWithOutgoing(message.Fields.DialogId))

                        const projectId = selectCurrentProjectId(store.getState())
                        if (
                            currentMessagePagination?.OmniUserId &&
                            projectId &&
                            selectedDialogId === message.Fields.DialogId
                        ) {
                            const getDialogSelector = dialogsApi.endpoints.getDialogMessages.select({
                                ...currentMessagePagination,
                                ProjectId: projectId
                            })
                            const getDialogQuery = getDialogSelector(store.getState())
                            const isNewMessage = !(
                                getDialogQuery.data &&
                                getDialogQuery.data.Messages.some(Message => Message.Fields.Id === message.Fields.Id)
                            )

                            if (isNewMessage) {
                                const currentOperator = store.getState().users.currentUser
                                message.Fields.Sender.Id = currentOperator.data?.Login || message.Fields.Sender.Id

                                dispatch(
                                    dialogsApi.util.updateQueryData(
                                        "getDialogMessages",
                                        currentMessagePagination,
                                        oldState => {
                                            oldState.Messages.push(message)
                                        }
                                    )
                                )
                                dispatch(getAIAssistHintByDialogId(selectedDialogId))
                                dispatch(getAISuggestsByDialogId(selectedDialogId))
                            }
                        }

                        return
                    }

                    if (selectedDialogId === message.Fields.DialogId && currentMessagePagination) {
                        dispatch(
                            dialogsApi.util.updateQueryData("getDialogMessages", currentMessagePagination, oldState => {
                                oldState.Messages.push(message)
                            })
                        )
                        dispatch(getAIAssistHintByDialogId(selectedDialogId))
                        dispatch(getAISuggestsByDialogId(selectedDialogId))
                    }

                    if (!isCached) {
                        dialogBadges = (await dispatch(refetchDialogBadges())).data
                    }

                    const badgeIndex = dialogBadges?.findIndex(
                        dialogBadge => dialogBadge.Client.OmniUserId === message.Fields.Sender.Id
                    )

                    if (badgeIndex !== undefined && badgeIndex > -1) {
                        const badge = dialogBadges?.[badgeIndex]

                        if (badge) {
                            const title =
                                getClientFullname(badge.Client) ||
                                i18next.t(`${tDialogsNotificationsNamespace}unknown-client`)
                            const unreadMessages = i18next.t(`${tDialogsNotificationsNamespace}unread-messages`, {
                                value: badge.UnreadMessages
                            })
                            const body = `${unreadMessages} ${message.Fields.Text}`
                            const dialogId = message.Fields.DialogId

                            if (!isStarterMessage(message)) {
                                dispatch(updateDialogBadge(badgeIndex, badge, message))
                            }
                            dispatch(dialogsApi.endpoints.putDisableDialogHold.initiate(dialogId))

                            if (!isStarterMessage(message)) {
                                notifyOperator(
                                    {
                                        title,
                                        body,
                                        dialogId,
                                        dispatch,
                                        projectId: badge.Project.Id,
                                        clientId: message.Fields.Sender.Id
                                    },
                                    { type: NotificationsTypes.NewMessage, message: String(badge.UnreadMessages + 1) }
                                )
                            }
                        }
                    }

                    dispatch(refetchActiveDialogsCount())
                } catch (e) {
                    logHubError(HUB_NAME, Events.HANDLE_MESSAGE, e)
                }
            },
            [Events.PINNED_MESSAGES]: async (data: unknown) => {
                try {
                    const currentMessagePagination = selectMessagesPaginationState(store.getState())

                    const incomingEntity = data as HubPinnedMessages

                    if (!currentMessagePagination) return

                    dispatch(
                        dialogsApi.util.upsertQueryData("getPinnedMessages", currentMessagePagination.OmniUserId, {
                            Messages: incomingEntity.Messages ?? []
                        })
                    )
                } catch (e) {
                    logHubError(HUB_NAME, Events.PINNED_MESSAGES, e)
                }
            }
        }
    }

    registerServerEvents() {
        handleEvents(this._events, this._hub.registerEvent.bind(this._hub))
    }

    unregisterServerEvents() {
        handleEvents(this._events, this._hub.unregisterEvent.bind(this._hub))
    }
}
